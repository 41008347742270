import React, { useCallback, useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import ImportColumnSelect from "components/Forms/ImportColumnSelect/ImportColumnSelect"
import {  httpUploadTransactionsExcelImport } from "services/transactions"
import { Row, Col, Card,CardBody, Container } from "stories/layout";
import { Input, DatePicker, Checkbox } from "stories/forms";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { PageHeader, PageContainer } from "components/Page"
import { useAppContext }from "contexts/AppContext"
import { Typography, Button, Link, IconButton, Modal, Popover, PopoverInfo, StepProgressBar, Spinner } from "stories/components";


import ImportNoBasicDataTable from 'views/app/ImportNoBasicDataTable/ImportNoBasicDataTable'
import ImportFoundedBasicDataV2 from 'views/app/ImportFoundedBasicData/ImportFoundedBasicDataV2'
import ImportCheckTransactionsV2 from 'views/app/ImportCheckTransactions/ImportCheckTransactionsV2'
import HelpText from "components/Info/HelpText.js"



const UploadExcelForm = ({handleResolve}, props) => {

    const { t } = useTranslation();
    const { enums } = useAppContext()

    let transactionTypes = enums?.transactions?.transaction?.type.filter(obj => obj.key === 2 || obj.key === 3 || obj.key === 20 || obj.key === 41 || obj.key === 30 )

    //add transactionTypes one item
    transactionTypes = [ ...transactionTypes, {key: 200, value: t('VAIHTO JÄTTÖ'), optional: true},]

    const [file, setFile] = useState(null);
    const [fileSummary, setFileSummary] = useState(null);
    const [columns, setColumns] = useState([]);
    const [mapping, setMapping] = useState({});
    const [mappings, setMappings] = useState([]);
    const [transactionTypemappings, setTransactionTypemappings] = useState([]);
    const [errorText, setErrorText] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [headers, setHeaders] = useState([]);
    const [headerRowIndex, setHeaderRowIndex] = useState(0);
    const [NordnetImport, setNordnetImport] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [nextStepBtn, setNextStepBtn] = useState(null);
    const [nextStepHandle, setNextStepHandle] = useState(1);
    const [basicDataRows, setBasicDataRows] = useState(null);
    const [foundedBasicDataRows, setFoundedBasicDataRows] = useState(null);
    const [checkTransactions, setCheckTransactions] = useState(null);
    const [exchange_transactions, setExchange_transactions] = useState()
    const [validBasicDatas, setValidBasicDatas] = useState(true)

    const [loadingSendExcel, setLoadingSendExcel] = useState(false);
    
    const portfolio = useStoreState((state) => state.portfolio?.portfolio);

    //--- Investime default options ---
    const initialOptions = [
      { key: 'date_transaction', value: t('importTransactionsV2_date_transaction','Kauppapäivä'), optional: false },
      { key: 'date_settlement', value: t('importTransactionsV2_date_settlement','Selvityspäivä (maksupäivä)'), optional: true },
      { key: 'type', value: t('importTransactionsV2_type','Tapahtumatyyppi'), optional: false },
      { key: 'ticker_symbol', value: t('importTransactionsV2_ticker_symbol','Arvopaperin tunnus'), optional: true },
      { key: 'quantity', value: t('importTransactionsV2_quantity','Määrä'), optional: false },
      { key: 'price', value: t('importTransactionsV2_price','A-hinta (kurssi)'), optional: false },
      { key: 'commission_total', value: t('importTransactionsV2_commission_total','Kokonaiskulut'), optional: false },
      { key: 'value_total', value: t('importTransactionsV2_value_total','Yhteensä (summa)'), optional: false },
      { key: 'reference_code', value: t('importTransactionsV2_reference_code','ID'), optional: true },
      { key: 'date_record', value: t('importTransactionsV2_date_record','Kirjauspäivä'), optional: true },
      { key: 'system_portfolio', value: t('importTransactionsV2_system_portfolio','Salkku'), optional: true },
      { key: 'name', value: t('importTransactionsV2_name','Arvopaperin nimi'), optional: true },
      { key: 'instrument_type', value: t('importTransactionsV2_instrument_type','Instrumenttityyppi'), optional: true },
      { key: 'isin', value: t('importTransactionsV2_isin','ISIN'), optional: true },
      { key: 'interest', value: t('importTransactionsV2_interest','Korko'), optional: true },
      { key: 'commission_total_currency', value: t('importTransactionsV2_commission_total_currency','Valuutta (kulut)'), optional: true },
      { key: 'value_total_currency', value: t('importTransactionsV2_value_total_currency','Valuutta (Summa)'), optional: true },
      { key: 'purchase_cost_total', value: t('importTransactionsV2_purchase_cost_total','Hankinta-arvo'), optional: true },
      { key: 'purchase_cost_total_currency', value: t('importTransactionsV2_purchase_cost_total_currency','Valuutta (Hankinta-arvo)'), optional: true },
      { key: 'profit', value: t('importTransactionsV2_profit','Tulos'), optional: true },
      { key: 'profit_currency', value: t('importTransactionsV2_profit_currency','Valuutta (Tulos)'), optional: true },
      { key: 'balance', value: t('importTransactionsV2_balance','Kokonaismäärä'), optional: true },
      { key: 'money_balance', value: t('importTransactionsV2_money_balance','Saldo'), optional: true },
      { key: 'exchange_rate', value: t('importTransactionsV2_exchange_rate','Vaihtokurssi'), optional: true },
      { key: 'notes', value: t('importTransactionsV2_notes','Tapahtumateksti'), optional: true },
      { key: 'date_voiding', value: t('importTransactionsV2_date_voiding','Mitätöintipäivä'), optional: true },
      { key: 'laskelma', value: t('importTransactionsV2_laskelma','Laskelma'), optional: true },
      { key: 'vahvistusnumero', value: t('importTransactionsV2_vahvistusnumero','Vahvistusnumero'), optional: true },
      { key: 'commission_basic_amount', value: t('importTransactionsV2_commission_basic_amount','Välityspalkkio'), optional: true },
      { key: 'commission_basic_amount_currency', value: t('importTransactionsV2_commission_basic_amount_currency','Valuutta (Välityspalkkio)'), optional: true },
      { key: 'price_total', value: t('importTransactionsV2_price_total','Kokonais hinta (määrä*A-hinta)'), optional: true },
      { key:'price_total_currency', value: t('importTransactionsV2_price_total_currency','Valuutta (Kokonais hinta)'), optional: true },
      { key:'stock_market', value: t('importTransactionsV2_stock_market','Pörssi'), optional: true },
    ];

    
    const fileInputRef = React.createRef();
    const [availableOptions, setAvailableOptions] = useState(initialOptions);
    const [transactionAvailableOptions, setTransactionAvailableOptions] = useState(null);
    const [transactionInitialOptions, setTransactionInitialOptions] = useState(null);
    const [transactionTypesExcel, setTransactionTypesExcel] = useState(null);
    
    //---loop transactions types and create like initialOptions but for transaction types---
    useEffect(() => {
        let newInitialOptions = []
        transactionTypes && transactionTypes.forEach((type) => {
            newInitialOptions.push({key:type.key, value:t(`transactionTypeValue_${type.key}`, type.value), optional:true})
        })
        setTransactionAvailableOptions(newInitialOptions)
        setTransactionInitialOptions(newInitialOptions)
    }, [])


    const handleButtonClick = () => {
      fileInputRef.current.click();
    };

    //---File upload---
    const handleFileChange = (event) => {
      const selectedFile = event.target.files[0];

      //if no selectedFile return and set errorText
      if (!selectedFile){
        setErrorText(t("importTransactionsV2_choose_file","Valitse tiedosto (.xlsx)"))
        return
      }
      setErrorText(null)

      //Check that file is xlsx
      if (selectedFile?.name?.split('.').pop() !== "xlsx" && selectedFile?.name?.split('.').pop() !== "xls"){
        setErrorText(t("importTransactionsV2_file_format","Tiedoston tulee olla xlsx tai xls muodossa"))
        return
      }


      setFile(selectedFile);
      setMappings({}); // Reset the mapping when a new file is uploaded
  
      // Parse the Excel file and extract column names
      const reader = new FileReader();
      reader.onload = (e) => {
        
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
  
        // Assuming the first sheet of the Excel file contains the data
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        let headerRow = null;
        for (let i = 0; i < jsonData.length; i++) {
          const row = jsonData[i];
          if (row.some((cell) => cell !== null && cell !== "")) {
            headerRow = row;
            break;
          }
        }

        
      
        if (headerRow !== null) {
          // Extract column names from the header row
          const header = headerRow;
      
          // Add Excel column letters to the header
          const headerWithLetters = header.map((column, index) => {
             const columnLetter = getExcelColumnLetter(index );
            return `${column}`;
          });
      
          setColumns(headerWithLetters);
          setMappings(header.map((column) => { return { key: column, value: "" } }));
        }
      };
      // Function to calculate Excel column letter
      function getExcelColumnLetter(columnNumber) {
          let columnName = "";
          while (columnNumber >= 0) {
          let remainder = columnNumber % 26;
          columnName = String.fromCharCode(65 + remainder) + columnName;
          columnNumber = Math.floor(columnNumber / 26) - 1;
          }
          return columnName;
      }
  
      reader.readAsArrayBuffer(selectedFile);
    };


    //---Read transaction types from Excel file---
    const readTypes = (index) => {
        const reader = new FileReader();
                  
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          
          // Assuming the first sheet of the Excel file contains the data
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          let cellValues = []
            
          let columnIndexToRead = index; // Change this to the index of the column you want to read
        
          // Start from index 1 to exclude the header row
          for (let i = 1; i < jsonData.length; i++) {
            const row = jsonData[i];
        
            // Check if the columnIndexToRead is within the row's length
            if (columnIndexToRead < row.length) {
              const cellValue = row[columnIndexToRead];
              cellValues.push(cellValue);
            }
          }
          const uniqueColumnValues = [...new Set(cellValues)];

          //filter undefined values out of uniqueColumnValues
          const filteredArray = uniqueColumnValues.filter(value => value !== undefined);

          //Convert all filteredArray values to string
          filteredArray.forEach((value, index) => {
            filteredArray[index] = String(value)
          })

          setTransactionTypesExcel(filteredArray)

          if(NordnetImport){

            setTransactionTypemappings(filteredArray.map((column) => { 
              if (column === "OSTO"){
                return { key: String(column), value: 2, optional: true }
              }
              if (column === "MYYNTI"){
                return { key: String(column), value: 3, optional: true }
              }
              if (column === "SPLIT"){
                return { key: String(column), value: 20, optional: true }
              }
              if (column === "VAIHTO AP-OTTO"){
                return { key: String(column), value: 41, optional: true }
              }
              if (column === "OSINKO"){
                return { key: String(column), value: 30, optional: true }
              }
              if (column === "VAIHTO AP-JÄTTÖ"){
                return { key: String(column), value: 200, optional: true }
              }

              return { key: String(column), value: "" } 
            }));

          }
          else {
          setTransactionTypemappings(filteredArray.map((column) => { return { key: String(column), value: "" } }));
          }
        };
        
        // Make sure to invoke the readAsArrayBuffer method on the FileReader with your Excel file variable
        reader.readAsArrayBuffer(file);
    }
  

    const handleOptionChange = (event, index, value) => {

        const selectedValue = event.target.value;
        if (selectedValue || true) {
            // if selected value is in available options, remove it from available options
            const removeAvailableOptions = availableOptions.filter(option => option.key !== selectedValue)
            // setAvailableOptions(removeAvailableOptions)
            //add earlier selected value back to available options (initialOptions where option.key == value)
            const addAvailableOptions = [ ...initialOptions.filter(option => option.key === value?.value), ...removeAvailableOptions] 
            //filter initial options based on addAvailableOptions, use map
            const newAvailableOptions = initialOptions.filter(option => addAvailableOptions.map(option => option.key).includes(option.key))

            setAvailableOptions(newAvailableOptions)
            
            let updatedMappings = [...mappings];
            updatedMappings[index] = {key: index, value: selectedValue};
            setMappings(updatedMappings);

            setErrorText(null)

            // Get transaction types from Excel file when the "type" column is selected
            if (selectedValue === "type") {
              readTypes(index)
            }

        }
    };

    const handleOptionChangeTransactionType = (event, index, value) => {
        const selectedValue = event.target.value;
        if (selectedValue || true) {

            // if selected value is in available options, remove it from available options
            const removeAvailableOptions = transactionAvailableOptions.filter(option => option.key != selectedValue)
            // setAvailableOptions(removeAvailableOptions)
            //add earlier selected value back to available options (initialOptions where option.key == value)
            const addAvailableOptions = [ ...transactionInitialOptions.filter(option => option.key == value?.value), ...removeAvailableOptions]
            //filter initial options based on addAvailableOptions, use map
            const newAvailableOptions = transactionInitialOptions.filter(option => addAvailableOptions.map(option => option.key).includes(option.key))

            setTransactionAvailableOptions(newAvailableOptions)

            let updatedMappings = [...transactionTypemappings];
            updatedMappings[index] = {key: updatedMappings[index]?.key, value: selectedValue};
            setTransactionTypemappings(updatedMappings);

            setErrorText(null)
        }
    };





    const getSelectedOptionsValue = (key) => {
        return initialOptions.filter(option => option.key === key?.value)?.[0]?.value
    }
    const getSelectedOptionsValueTransactionType = (key) => {
        return transactionInitialOptions.filter(option => option.key == key?.value)?.[0]?.value
    }

  
    const handleFileUpload = (transaction_save, chect_transactions) => {

      //Validate data before sending

      //All required columns must be selected
      if (availableOptions.length > 0) {
          if (availableOptions.filter(option => option.optional === false).length > 0) {
              setErrorText("Kaikkia pakollisia Investimen sarakkeita * ei ole valinnalla yhdistetty")
              return
          }

      }

      //At least one transaction type must be selected
      if (!transaction_save && transactionAvailableOptions && transactionAvailableOptions.length > 0) {
        if (transactionAvailableOptions.filter(option => option.optional === false).length > 0) {
            setErrorText("Kaikkia pakollisia Investimen tapahtuman tyyppejä ei ole valinnalla yhdistetty")
            return
        }
        //if availableOptions list are same as initialOptions list, then all options are selected
        if (transactionAvailableOptions.length === transactionInitialOptions.length) {
          setErrorText("Yhtään Investimen tapahtuman tyyppiä ei ole valittu")
          return
        }
      }

      //Check that transaction not already exists
      if (transaction_save && checkTransactions?.already_imported_transactions && checkTransactions?.already_imported_transactions.length > 0) {
        setErrorText(t('importTransactionsV2_cant_import_same_transactions','Jo olemassa olevia tapahtumia ei voi tuoda uudestaan'))
        return
      }

      //Check that there is no younger transaction in portfolio
      if (transaction_save && checkTransactions?.already_exists_younger_transactions && checkTransactions?.already_exists_younger_transactions.length > 0) {
        setErrorText(t('importTransactionsV2_cant_import_older_what_is_in_portfolio','Et voi tuoda vanhempia tapahtumia kuin mitä salkussa jo on olemassa ko arvopaperille')) 
        return
      }
      


      
      let data_valid = true
      if (transaction_save){
        exchange_transactions && exchange_transactions?.forEach((row, index) => {
          const exchange_in_transactions = row?.exchange_in_transactions
          const purchase_cost_total_sum = row?.purchase_cost_total_sum



                if (exchange_in_transactions && exchange_in_transactions.length > 0) {
                    let new_pusrchase_cost_total_sum = 0
                    let new_purchase_cost_percentage_sum = 0
                    for (const key in exchange_in_transactions) {
                      const item = exchange_in_transactions[key];
                      const value = Number(item?.new_purchase_cost) || 0
                      new_pusrchase_cost_total_sum = new_pusrchase_cost_total_sum + value
                      new_purchase_cost_percentage_sum = new_purchase_cost_percentage_sum + (Number(item?.new_purchase_cost_percentage) || 0)
                    }
                    if ( Math.abs(new_pusrchase_cost_total_sum - purchase_cost_total_sum) > 0.001){
                      setErrorMessage(t("importTransactionsV2_purchase_cost_total_sum_not_equal", "Uudelle tai uusille arvopapereille asetetun hankintamenon yhteismäärän tulee olla sama kuin vanhan arvopaperin hankintameno."))
                      // setErrorMessage(`${purchase_cost_total_sum}  -  ${new_pusrchase_cost_total_sum}`)
                      data_valid = false
                      return
                    }
                }
        })
      }

      if (data_valid == false){
        return
      }

      setErrorText(null)
      setErrorMessage(null)
      

      // Perform the file upload to your Django backend here
      // Send the file and the mapping to the API endpoint
      // Example: Using the Fetch API
      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileSummary', fileSummary);
      formData.append('column_mapping', JSON.stringify(mappings));
      formData.append('types', JSON.stringify(transactionTypemappings));
      formData.append('header_row_index', headerRowIndex);
      formData.append('transaction_save', transaction_save);
      formData.append('check_transactions', chect_transactions);
      formData.append('basicDataRows', JSON.stringify(basicDataRows));
      formData.append('exchange_transactions', JSON.stringify(exchange_transactions));

      formData.append('portfolio', portfolio.id);
      setLoadingSendExcel(true)
      httpUploadTransactionsExcelImport(formData).then(res => {
        let b_data = []
        if (res?.data?.data.length > 0){
          b_data = res?.data?.data.map((item)=>{
            return {
              ...item,
              size: 0,
              currency_label: "EUR",
              security_type: "private_stock",
            }
          })
        }

        setBasicDataRows(b_data)
        setFoundedBasicDataRows(res?.data?.find_basic_data)
        setCheckTransactions(res?.data?.data)
        setNextStepHandle((prevStep) => prevStep + 1)
        setLoadingSendExcel(false)

        // setExcelFile(null)
        // fileInputRef.current.value = "";
        // notify({ title:t("Excel tuotu onnistuneesti"), type: "success", icon: "fas fa-check"})
        // getTransactions()
      }, error => {
        setBasicDataRows([])
        setCheckTransactions(null)
        setFoundedBasicDataRows([])
        setErrorText("Tapahtumien tuonti epäonnistui")
        if(error?.data?.message) {
          setErrorMessage(t(error?.data?.message))
        }
        if(error?.data?.general) {
          //replace : with . to avoid rendering issues
          const general = error?.data?.general?.toString()
          const formattedError = general.replace(/:/g, '.')
          setErrorMessage(t(formattedError))
        }
        setNextStepHandle((prevStep) => prevStep + 1)

      }).finally(() => {
        setLoadingSendExcel(false)

        // setLoadingImport(false)
      })

    
    };

    const handleFileUploadWithBasicData= () => {
      if (basicDataRows.length > 0) {
        let pluralBasicDataSymbol = {}
        let name_given = true

        for (const security of basicDataRows) {
          if (pluralBasicDataSymbol[security?.ticker_symbol]) {
            pluralBasicDataSymbol[security?.ticker_symbol]++;
          } else {
            pluralBasicDataSymbol[security?.ticker_symbol] = 1;
          }
          if (security?.name == "" || security?.name == null) {
            name_given = false
          }

        }
        let emptyRows = basicDataRows.filter(row => 'security_type' in row === false || 'size' in row === false ||'currency_label' in row === false || row?.security_type === null || row?.security_type == "" || row?.currency_label === null ||row?.currency_label == "" || row?.size === null || row?.size ==="" )

        let not_valid = false
        emptyRows && emptyRows.forEach(row => {
          if (pluralBasicDataSymbol[row?.ticker_symbol] == 1){
            setErrorText(t('importTransactionsV2_check_basic_datas','Tarkista ja täytä kaikki arvopaperin tiedot. Mikäli useampi arvopaperi samalla symbolilla vähintään yhdestä on luotava arvopaperi.'))
            not_valid = true
            
          }
          if (pluralBasicDataSymbol[row?.ticker_symbol] > 1){
            pluralBasicDataSymbol[row?.ticker_symbol]--;
          }
        });
        if (not_valid){
          return
        }
        if (name_given == false){
          setErrorText(t('importTransactionsV2_give_name','Anna kaikille arvopapereille nimi'))
          return
        }
      }
      setErrorText(null)
      setErrorMessage(null)

      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileSummary', fileSummary);

      formData.append('column_mapping', JSON.stringify(mappings));
      formData.append('types', JSON.stringify(transactionTypemappings));
      formData.append('header_row_index', headerRowIndex);
      formData.append('portfolio', portfolio.id);
      formData.append('basicDataRows', JSON.stringify(basicDataRows));
      formData.append('check_transactions', true);

      formData.append('basicDataImport', true)
      setLoadingSendExcel(true)
      httpUploadTransactionsExcelImport(formData).then(res => {

        setBasicDataRows(res?.data?.data)
        setFoundedBasicDataRows(res?.data?.find_basic_data)
        setCheckTransactions(res?.data?.data)

        setNextStepHandle((prevStep) => prevStep + 1)
        setLoadingSendExcel(false)

      // setExcelFile(null)
      // fileInputRef.current.value = "";
      // notify({ title:t("Excel tuotu onnistuneesti"), type: "success", icon: "fas fa-check"})
      // getTransactions()
    }, error => {
      setBasicDataRows([])
      setCheckTransactions(null)
      setFoundedBasicDataRows([])
      setErrorText("Tapahtumien tuonti epäonnistui")
      if(error?.data?.message) {
        setErrorMessage(t(error?.data?.message))
      }
      if(error?.data?.general) {
        //replace : with . to avoid rendering issues
        const general = error?.data?.general?.toString()
        const formattedError = general.replace(/:/g, '.')
        setErrorMessage(t(formattedError))
      }
      setNextStepHandle((prevStep) => prevStep + 1)
      
    }).finally(() => {
      setLoadingSendExcel(false)
      // setLoadingImport(false)
    })

  };

    const handleCheckboxChange = (isChecked) => {
      setNordnetImport(!NordnetImport)
      if(!NordnetImport){
        setMappings([ 
            { key: 0, value: 'reference_code', optional: true },
            { key: 1, value: 'date_record', optional: true },
            { key: 2, value: 'date_transaction', optional: false },
            { key: 3, value: 'date_settlement', optional: true },
            { key: 4, value: 'system_portfolio', optional: true },
            { key: 5, value: 'type', optional: false },
            { key: 6, value: 'ticker_symbol', optional: true },
            { key: 7, value: 'instrument_type', optional: true },
            { key: 8, value: 'isin', optional: true },
            { key: 9, value: 'quantity', optional: false },
            { key: 10, value: 'price', optional: false },
            { key: 11, value: 'interest', optional: true },
            { key: 12, value: 'commission_total', optional: false },
            { key: 13, value: 'commission_total_currency', optional: true },
            { key: 14, value: 'value_total', optional: true },
            { key: 15, value: 'value_total_currency', optional: true },
            { key: 16, value: 'purchase_cost_total', optional: true },
            { key: 17, value: 'purchase_cost_total_currency', optional: true },
            { key: 18, value: 'profit', optional: true },
            { key: 19, value: 'profit_currency', optional: true },
            { key: 20, value: 'balance', optional: true },
            { key: 21, value: 'money_balance', optional: true },
            { key: 22, value: 'exchange_rate', optional: true },
            { key: 23, value: 'notes', optional: true },
            { key: 24, value: 'date_voiding', optional: true },
            { key: 25, value: 'laskelma', optional: true },
            { key: 26, value: 'vahvistusnumero', optional: true },
            { key: 27, value: 'commission_basic_amount', optional: true },
            { key: 28, value: 'commission_basic_amount_currency', optional: true },
            { key: 29, value: 'stock_market', optional: true },

        ]);

        setTransactionTypemappings([
            { key: "OSTO", value: 2, optional: true },
            { key: "MYYNTI", value: 3, optional: true },
            { key: "SPLIT", value: 20, optional: true },
            { key: "VAIHTO AP-OTTO", value: 41, optional: true },
            { key: "OSINKO", value: 30, optional: true },
            { key: "VAIHTO AP-JÄTTÖ", value: 200, optional: true },
        ]);
        
        setAvailableOptions([])
        setErrorText(null)
        readTypes(5)
      }
      else{
        setMappings(columns.map((column) => { return { key: column, value: "" } }));
        setAvailableOptions(initialOptions)
      }
    };

    const getFileName = useCallback((file) => {
        if (file) {
          return file.name;
        }
        return t("(xlsx, xls)");
    }, [file]);


    const getExcelColumnLetter = (columnNumber) => {
        let columnName = "";
        while (columnNumber >= 0) {
            let remainder = columnNumber % 26;
            columnName = String.fromCharCode(65 + remainder) + columnName;
            columnNumber = Math.floor(columnNumber / 26) - 1;
        }
        return columnName;
    }

    const numberToColumnLetter = (number) =>  {
        let columnLetter = '';
        while (number >= 0) {
          columnLetter = String.fromCharCode((number % 26) + 65) + columnLetter;
          number = Math.floor(number / 26) - 1;
        }
        return columnLetter;
      }



    const handleSelect = (index, selectedValue) => {
        // Update the mapping for the selected column at the given index
        const updatedMappings = [...mappings];
        updatedMappings[index] = selectedValue;
        setMappings(updatedMappings);
      };

    const handleSelectTransactionType = (index, selectedValue) => {
        // Update the mapping for the selected column at the given index
        const updatedMappings = [...transactionTypemappings];
        updatedMappings[index] = selectedValue;
        setTransactionTypemappings(updatedMappings);
        };
      
    const getErrorText = useCallback(() => {
        if (errorText) {
            return (
              <>{errorText}</>
            );
        }
        return null;
    }, [errorText, validBasicDatas, file]);


    const moveToSecondStep = () => {
      if(!file){
        setErrorText(t("importTransactionsV2_choose_file","Valitse tiedosto (.xlsx)"))
        return
      }
      setNextStepHandle((prevStep) => prevStep + 1)
    }

          
    const getContext = useCallback(() => {
      if (nextStepHandle === 1) {
          return "import_transactions_step1"
      }
      if (nextStepHandle === 2) {
          return "import_transactions_step2"
      }
      if (nextStepHandle === 3) {
          return "import_transactions_step3"
      }
      if (nextStepHandle === 4) {
          return "import_transactions_step4"
      }
      if (nextStepHandle === 5) {
          return "import_transactions_step5"
      }
      return null;
  }, [nextStepHandle]);


    const getColumns= useCallback(() => {
      return(
        <Container fluid>
          <div className="row justify-content-start">
            <div className="ml-3 mb-3 mt-4">
              <Checkbox
                id="unique_checkbox_id"
                name='NordnetImport'
                checked={NordnetImport}
                label={t("import_from_nordnet", "Tuonti Nordnetistä")}
                onChange={(e) => {handleCheckboxChange(e.target.checked)}}
              />
            </div>
          </div>

          <Row className="mb-3">
            <Col sm={12} lg={4} className="d-flex align-items-center">
              <label className="mb-1 pr-1 header-bold">{t("importTransactionsV2_excel_column", "Tuotavan excelin sareke")}</label>
            </Col>
            <Col sm={12} lg={8} >
              <label className="mb-1 pr-1 header-bold">{t("importTransactionsV2_investime_column", "Investime sarake")}</label>
            </Col>
          </Row>
          
          {columns.map((column, index) => (
            <div key={index}>
              <Row className="mb-3">
                <Col sm={12} lg={4} className="d-flex align-items-center">
                  <Typography variant="h4" style={{transition: 'padding-left 0.5s' }} className={`mb-1 pr-1 header ${mappings[index]?.value !== "" && mappings[index]?.value !== " " ? ' pl-3 transitioning text-warning' : 'text-dark'}`}>{numberToColumnLetter(index)} - {column}</Typography>
                </Col>
                <Col sm={12} lg={8} >
                  <ImportColumnSelect
                    initialOptions={initialOptions}
                    selectedOptions={mappings}
                    availableOptions={availableOptions}
                    onChange={(e) => handleOptionChange( e, index, mappings[index])}
                    value={getSelectedOptionsValue(mappings[index])}
                    label =" "
                    placeholder="Valitse"
                    name={column+index}
                    onSelect={(selectedValue) =>handleSelect(index, selectedValue, )}
                    className={mappings[index]?.value !== "" && mappings[index]?.value !== " " ? 'text-warning' : 'text-dark'}
                  />
                </Col>
              </Row>

            </div>
          ))}
        </Container>
      )
    }, [columns, mappings, availableOptions, initialOptions, NordnetImport]);


    const getColumnsTransactionType= useCallback(() => {
      return(
        <Container fluid>
          {
            transactionTypesExcel && transactionTypesExcel.length > 0 && (
              <Row className="my-3 mt-5">
                  <Col sm={12} lg={4} className="d-flex align-items-center">
                    <label className="mb-1 pr-1 header-bold">{t("importTransactionsV2_excel_types", " Tuotavan excelin tapahtumien tyypit")}</label>
                  </Col>
                  <Col sm={12} lg={8} >
                    <label className="mb-1 pr-1 header-bold">{t("importTransactionsV2_investime_types", "Investimen tapahtumien tyypit")}</label>
                  </Col>
                </Row>
            )
          }

          {transactionTypesExcel && transactionTypesExcel.map((column, index) => (
            <div key={index}>
              <Row className="mb-3">
                <Col sm={12} lg={4} className="d-flex align-items-center">
                  <Typography variant="h4" style={{transition: 'padding-left 0.5s' }} className={`mb-1 pr-1 header ${transactionTypemappings[index]?.value !== "" && transactionTypemappings[index]?.value !== " " ? ' pl-3 transitioning text-warning' : 'text-dark'}`}>{column}</Typography>
                </Col>
                <Col sm={12} lg={8} >
                  <ImportColumnSelect
                      initialOptions={transactionInitialOptions}
                      selectedOptions={transactionTypemappings}
                      availableOptions={transactionAvailableOptions}
                      onChange={(e) => handleOptionChangeTransactionType( e, index, transactionTypemappings[index])}
                      value={getSelectedOptionsValueTransactionType(transactionTypemappings[index])}
                      label =" "
                      placeholder="Valitse"
                      name={column+index}
                      onSelect={(selectedValue) => handleSelectTransactionType(index, selectedValue, )}
                      className={transactionTypemappings[index]?.value !== "" && transactionTypemappings[index]?.value !== " " ? 'text-warning' : 'text-dark'}
                />
                </Col>
              </Row> 
            </div>
          ))}
        </Container>
      )
    }
    , [transactionAvailableOptions, transactionInitialOptions, transactionTypemappings, transactionTypesExcel]);

  
    return (
      <div>
        <Row className="justify-content-center mb-2">
          <Col xl="4" lg="7" md="8" xs="10">
            <StepProgressBar steps={5} showNextButton={false} takeNextBtnState={setNextStepBtn} givenActiveStep={nextStepHandle} />
          </Col>
        </Row>

        <Row className="justify-content-center mb-4">
          <Col xl="12">
            <div className="bg-default px-3 py-1" id="import-helptext" rounded>
              <div className="mt-3">
                <HelpText contentKey={getContext()} parentElementId="import-helptext" showButton={false} popoverOpen={false} className="mb-0 text-center mt-2"/>
              </div>
            </div>
          </Col>
        </Row>


        {nextStepHandle === 1 && (
          <>
            <div>
              <Typography variant="h4" className="my-1 mt-4 pr-1 text-dark">{t("importTransactionsV2_import_excel", "1. Kirjaukset")}</Typography>
              <div className="mb-5">

                <label className="mb-0" htmlFor="fileInput"><Button onClick={handleButtonClick}>{t("Choose file")}</Button></label>
                <input
                  ref={fileInputRef}
                  type="file"
                  id="fileInput"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
                <div className="custom-file-upload " style={{ display: 'inline-block', marginLeft: '8px' }}>
                  {getFileName(file)}
                </div>
              </div>

            </div>
            <Button  className=" mt-2 mr-auto ml-auto" color="secondary" onClick={() =>  moveToSecondStep()}>
              {t("Jatka")}
            </Button>
            {errorText && <Typography variant="h5" className="my-1 pr-1 text-danger">{getErrorText()}</Typography>}
          </>
        )}
        
        {columns.length > 0 && nextStepHandle === 2 && (
          <div className="mt-4">
             <PageHeader className="text-dark mb-5" title={t("importTransactionsV2_connect_columns","Yhdistä sarakkeet")}></PageHeader>

            {getColumns()}
            {getColumnsTransactionType()}

            <Button  className=" mt-2 mr-auto ml-auto" color="secondary" onClick={() => handleFileUpload(false,false)}>
              {loadingSendExcel?(<><Spinner animation="border" size="xs" className="spinner-height-15 mx-5"/></>): t("Tuo tiedot")}
            </Button>
            {errorText && <Typography variant="h5" className="my-1 pr-1 text-danger">{errorText}</Typography>}
          </div>
        )}
        {nextStepHandle >= 3 && basicDataRows.length > 0 ? (
          <>
            <ImportNoBasicDataTable basicDataRows={basicDataRows} setBasicDataRows={setBasicDataRows} setValidBasicDatas={setValidBasicDatas}/>
            <ImportFoundedBasicDataV2 basicDataRows={foundedBasicDataRows} setBasicDataRows={setFoundedBasicDataRows}/>

            <Button  className=" mt-2 mr-auto ml-auto" color="secondary"  onClick={handleFileUploadWithBasicData}>
              {loadingSendExcel?(<><Spinner animation="border" size="xs" className="spinner-height-15 mx-5"/></>): t("importTransactionsV2_create_basicdatas","Luo arvopaperit ja jatka")}
            </Button>
            {errorText && !validBasicDatas && <Typography variant="h5" className="my-1 pr-1 text-danger">{getErrorText()}</Typography>}
            {errorMessage && <Typography variant="h5" className="my-1 pr-1 text-danger">{errorMessage}</Typography>}
          </>
        ): nextStepHandle ==3 && (
          <>
            <div>
              {/* <label className="mb-1 pr-1 header-bold"><i className="fas fa-check-circle text-success mr-2"></i>{t("importTransactionsV2_all_bd_found", "Kaikille arvopapereille löytyi vastine")}</label> */}
            </div>
            <ImportFoundedBasicDataV2 basicDataRows={foundedBasicDataRows} setBasicDataRows={setFoundedBasicDataRows}/>
            <Row className="justify-content-center">
              <Col>
                <Button  className="mt-2 m-auto" color="secondary" onClick={() => handleFileUpload(false,true)}>
                 {loadingSendExcel?(<><Spinner animation="border" size="xs" className="spinner-height-15 mx-5"/></>): t("Jatka")}
                </Button>
              </Col>
            </Row>
            {/* {errorText && !validBasicDatas && <Typography variant="h5" className="my-1 pr-1 text-danger">{getErrorText()}</Typography>} */}
            {errorMessage && <Typography variant="h5" className="my-1 pr-1 text-danger">{errorMessage}</Typography>}
          </>
        )}
        {nextStepHandle === 4 && (
          <>
            <div>
              {/* <label className="mb-5 pr-1 header-bold">{t("importTransactionsV2_transactions_validation", "Kirjausten oikeellisuus")}</label> */}
            </div>
              {!errorText && <ImportCheckTransactionsV2 checkTransactions={checkTransactions} setCheckTransactions={setCheckTransactions} exchange_transactions={exchange_transactions} setExchange_transactions={setExchange_transactions} />}
            <Row className="justify-content-center mt-3">
              <Col>
                <Button  className="mt-2 m-auto" color="secondary" onClick={() => handleFileUpload(true,true )}>
                {loadingSendExcel?(<><Spinner animation="border" size="xs" className="spinner-height-15 mx-5"/></>): t("Tallenna ja luo kirjaukset")}
                </Button>
              </Col>
            </Row>
            {errorText && <Typography variant="h5" className="my-1 pr-1 text-danger">{errorText}</Typography>}
            {errorMessage && <Typography variant="h5" className="my-1 pr-1 text-danger">{errorMessage}</Typography>}
          </>
        )}
        {nextStepHandle === 5 && (
          <>
            <div>
              {!errorMessage && !errorText ?(
              
                <div className="d-flex justify-content-start mb-4 mt-3">
                <div className="mr-2" >
                  <i className="fas fa-check-circle text-success"></i>
                </div>
                <div >
                  <Typography variant="h3" className="text-dark " >  <label className="mb-1 pr-1 header-bold text-dark">{t("importTransactionsV2_end_success", "Kirjausten tuonti onnistui")}</label></Typography>
                </div>
              </div>
              ):(
                <label className="mb-1 pr-1 header-bold">{t("importTransactionsV2_end_fail", "Kirjausten tuonnissa jotain ongelmia")}</label>
              )}
            </div>
            <Row className="justify-content-center mt-5">
              <Col>
                <Button  className="mt-5 m-auto" color="secondary" onClick={() => handleResolve()}>
                  {t("Sulje")}
                </Button>
              </Col>
            </Row>
            {errorText && <Typography variant="h5" className="my-1 pr-1 text-danger">{errorText}</Typography>}
            {errorMessage && <Typography variant="h5" className="my-1 pr-1 text-danger">{errorMessage}</Typography>}
          </>
          )
        }
      </div>
    );
  };
  
  export default UploadExcelForm;

